import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql } from 'gatsby';
import constants from '../constants';
import Layout from '../components/Layout';
import Title from '../components/Title';
import PaginatedRoll from '../components/PaginatedRoll';

export default ({ data, pageContext }) => {
  const title = decodeURIComponent(pageContext.name);

  return (
    <Layout>
      <Helmet titleTemplate="%s | ヒナタビ">
        <title>{title}のご利益に関連する神社一覧</title>
        <meta
          name="description"
          content={`神社／パワースポット検索サイト・ヒナタビで${title}のご利益を受けられる神社の一覧です`}
        />
      </Helmet>
      <Title>{title}</Title>
      <PaginatedRoll
        type={constants.articleTypes.blog}
        data={data}
        pageContext={pageContext}
      />
    </Layout>
  );
};

export const query = graphql`
  query blogBlessings($skip: Int!, $limit: Int!, $name: [String]) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          blessings: { in: $name }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            shrines
            blessings
            templateKey
            date(formatString: "YYYY.MM.DD")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
